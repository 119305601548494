<template>
	<div class="container">
		<div class="title-wrap">
			<h2 class="title">비밀번호 찾기</h2>
		</div>
		<form class="form" @submit.prevent="findpw">
			<el-input placeholder="이메일" v-model="email" clearable :disabled="!findpwLoad || phase!=1"> </el-input>
			<el-input placeholder="인증번호" v-model="code" clearable :disabled="!findpwLoad || phase!=2" v-if="phase == 2"> </el-input>
			<el-input v-if="phase == 3"
				placeholder="새 비밀번호"
				v-model="password"
				:disabled="!findpwLoad || phase!=3"
				show-password
			></el-input>
			<el-input v-if="phase == 3"
				placeholder="새 비밀번호 확인"
				v-model="password2"
				:disabled="!findpwLoad || phase!=3"
				show-password
			></el-input>
			
			<button class="btn-main btn-full mb-20" :disabled="!findpwLoad">{{findpwButtonText}}</button>
			<p class="error">{{findpwErrorText}}</p>
			
			<br/>
			<hr />
			<div class="signup-wrap">
				<router-link to="/login" class="find-password">이미 비밀번호를 찾으셨나요?</router-link>
			</div>
		</form>
	</div>
</template>

<script>
import axios from "axios";
import {apiUrl} from '../constants/config';
export default {
	data() {
		return {
			email: '',
			password: '',
			password2: '',
			phase: 1,
			code: '',
			email_verify_key: '',
			findpwLoad: true,
			findpwButtonText: "다음 단계",
			findpwErrorText: ""
		}
	},
	methods: {
		findpw(){
			var vm = this;

			if(vm.phase == 1){

				if(vm.email == "")
				{
					vm.findpwErrorText = "이메일을 입력하세요."
					return;
				}
				var regEmail = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i; //eslint-disable-line
				if(this.email.match(regEmail) == null){
					vm.findpwErrorText = "올바른 이메일을 입력하세요."
					return;
				}

				vm.findpwErrorText = "";
				vm.findpwLoad = false;
				vm.findpwButtonText = "처리 중";
				axios.post(apiUrl+'/user/findpw',{
					phase: vm.phase,
					email: vm.email
				}, { withCredentials: true })
				.then(function(response) {
					if(response.data.success)
					{
						vm.email_verify_key = response.data.email_verify_key;
						vm.phase = 2;
						vm.findpwLoad = true;
						vm.findpwButtonText = "다음 단계";
						vm.findpwErrorText = "입력하신 이메일로 인증번호를 보내드렸습니다.";
					}
					else{
						vm.findpwLoad = true;
						vm.findpwButtonText = "다음 단계";
						vm.findpwErrorText = response.data.message;
					}
				})
				.catch(function(error) {
					console.log(error);
				});


			}
			else if(vm.phase == 2){
				if(vm.code == "")
				{
					vm.findpwErrorText = "인증번호를 입력하세요."
					return;
				}

				vm.findpwErrorText = "";
				vm.findpwLoad = false;
				vm.findpwButtonText = "처리 중";
				axios.post(apiUrl+'/user/findpw',{
					phase: vm.phase,
					email: vm.email,
					code: vm.code,
					email_verify_key: vm.email_verify_key
				}, { withCredentials: true })
				.then(function(response) {
					if(response.data.success)
					{
						vm.phase = 3;
						vm.findpwLoad = true;
						vm.findpwButtonText = "비밀번호 찾기";
						vm.findpwErrorText = "";
					}
					else{
						vm.findpwLoad = true;
						vm.findpwButtonText = "다음 단계";
						vm.findpwErrorText = response.data.message;
					}
				})
				.catch(function(error) {
					console.log(error);
				});

			}

			else {
				if(vm.password == "")
				{
					vm.findpwErrorText = "새 비밀번호를 입력하세요.";
					return;
				}
				if(vm.password2 == "")
				{
					vm.findpwErrorText = "새 비밀번호 확인을 입력하세요.";
					return;
				}
				
				if(vm.password.length < 8 || vm.password.length > 20)
				{
					vm.findpwErrorText = "비밀번호는 8~20자로 설정해주세요.";
					return;
				}
		
				vm.findpwErrorText = "";
				vm.findpwLoad = false;
				vm.findpwButtonText = "처리 중";
				axios.post(apiUrl+'/user/findpw',{
					phase: vm.phase,
					email: vm.email,
					code: vm.code,
					email_verify_key: vm.email_verify_key,
					password: vm.password,
				}, { withCredentials: true })
				.then(function(response) {
					if(response.data.success)
					{
						vm.$swal.fire(
						'비밀번호가 변경되었습니다.',
						"새로운 비밀번호로 로그인하실 수 있습니다.",
						'success'
					).then(() => {
						vm.$router.push("/login");
					} 
					)
					
					}
					else{
						vm.findpwLoad = true;
						vm.findpwButtonText = "비밀번호 찾기";
						vm.findpwErrorText = response.data.message;
					}
				})
				.catch(function(error) {
					console.log(error);
				});

				

			}
		}
	},
	beforeCreate(){
		if(this.$store.state.user.loggedIn)
			this.$router.push("/");
	}
}
</script>

<style scoped>
.signup-wrap {
	padding-top: 20px;
}

.signup-wrap .find-password {
	float: right;
}

.error {
	color: red;
	font-size: 14px;
}

.sns-login-wrap button {
	margin-bottom: 20px;
	border: 1px solid #e5e5e5;
}

.sns-login-wrap .sns-img {
	width: 24px;
	float: left;
	border-radius: 30px;
	overflow: hidden;
}
</style>
